// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

//import $ from 'jquery';
import React from 'react';
import { 
	ContentLayout,
	Container,
	Header,
	Link,
	Button,
	Alert,
	SpaceBetween,
	Icon,
	FormField,
	Input,
	ColumnLayout
} from "@cloudscape-design/components";
import { SessionContext } from '../../contexts/session';
import { Layout } from '../../layouts/payments/layout';
import { PaymentForm, PaymentStatus } from "../../components/payments";
import { SubmissionProxy } from "../../services/payment";
import { v4 as uuidv4 } from 'uuid';

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function PaymentInitiation() {
  
	const session = React.useContext(SessionContext);

	const [token,setToken] = React.useState(session.user.username);
	const [status,setStatus] = React.useState(null);
	const [payment,setPayment] = React.useState({});
	const [icon,setIcon] = React.useState("angle-right-double");

	async function initiatePayment () {
		
		if (icon == "angle-right-double") {
			console.log("initiatePayment - " + token);
			setIcon("refresh");
		
			var copyPayment = payment;
			setPayment({});

			// TODO: remove this line once the input form allows for alias and non-alias payments
			payment.recipientAlias = null;

      		var proxy = new SubmissionProxy();
			proxy.submit({
				payment: payment,
				success: async function (data) {
					console.log("payment-initiation success");
					console.log("success(data): " + JSON.stringify(data));
					setPayment(copyPayment);
					setStatus({ status: "accepted" });
					await sleep(2000);
					copyPayment.idClearing = uuidv4();
					setPayment(copyPayment);
					setStatus({ status: "complete" });
				},
				error: function (data) {
					console.log("payment-initiation error");
					console.log("error(data): " + JSON.stringify(data));
				}
			});

			if (status == null) {
				setStatus({ status: "submitted" });
			}
			else {
				setStatus(null);
			}			
		}
		else {
			console.log("resetPayment - " + token);
			setIcon("angle-right-double");
			setToken(session.user.username);
			setPayment({});
			setStatus(null);
		}
	}
	
	var details = <PaymentForm token={token} payment={payment} />;
	if (status != null) {
		details = <PaymentStatus status={status} payment={payment} />;
	}

	var originator = "Originator: " + token;

	return (<>
		<Layout active={{ text: "Initiation", href: "/payment-initiation" }}>

	<ContentLayout
		header={
			<SpaceBetween size="m">
			<Header 
				variant="h1"
				info={<Link href="/payment-status">Check Status</Link>}
				description="Send a Payment"
				actions={<Button variant="primary" onClick={initiatePayment}><Icon name={icon} /></Button>}
			>Payment Initiation</Header>
			</SpaceBetween>
		}
	>
			<Container
				header={
					<Header
						variant="h1"
						description=""
					>Create Payment Instruction</Header>
				}
			>{details}</Container>

	</ContentLayout>

		</Layout>
	</>);
}