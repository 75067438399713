// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

//import $ from 'jquery';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
	ContentLayout,
	Container,
	Header,
	Link,
	Button,
	Alert,
	SpaceBetween,
	Icon,
	FormField,
	Input,
	Box
} from "@cloudscape-design/components";
import { SessionContext } from '../../contexts/session';
import { Layout } from '../../layouts/payments/layout';
import { AccountProxy } from "../../services/payment";
import { AccountList } from "../../components/transactions";
//import { PaymentDetails } from "../../components/payments";
//import API from "../../endpoints";

export function PaymentActivity() {

  const navigate = useNavigate();
  const [activeHref,setActiveHref] = React.useState(window.location.pathname);

	const session = React.useContext(SessionContext);

	const [token,setToken] = React.useState(session.user.username);
	const [customerID, setCustomerID] = React.useState(null);
	//const [payment, setPayment] = React.useState(null);
  const [history, setHistory] = React.useState([]);

	function onLogin (token) {
		console.log("onAuthenticate - " + token);
		setToken(token);
	}
  
  function onFollow (event) {
    event.preventDefault();
    setActiveHref(event.detail.href);
    navigate(event.detail.href);
  }
  	
	function loadHistory () {
		console.log("loadHistory - " + token + " / " + customerID);
    console.log("seesion user: " + JSON.stringify(session.user.customer.accounts));
		
		var rqLookup = { 
			id: session.user.customer.id,
			token: session.user.customer.token,
      routing: session.user.customer.accounts[2].routing,
      account: session.user.customer.accounts[2].account
		};
    
    var proxy = new AccountProxy();
    proxy.submit({
      lookup: rqLookup,
      success: async function (data) {
        console.log("account-lookup success");
        //console.log("success(data): " + JSON.stringify(data));
        if (data.length > 0) {
          console.log("transactions: " + data.length);
          setHistory(data);          
        }
        
      },
      error: function (data) {
        console.log("account-lookup error");
        console.log("error(data): " + JSON.stringify(data));
      }
    });
    
		/*
    $.post(API.status, JSON.stringify(rqSearch), function(rsSearch, status){
				setPayment(rsSearch);
				//console.log("payment: " + JSON.stringify(rsSearch));
		});
    */
	}

	return (<>
		<Layout active={{ text: "Activity", href: "/payment-activity" }}>

	<ContentLayout
		header={
			<SpaceBetween size="m">
			<Header 
				variant="h1"
				info={<Link href="/payment-status" onFollow={onFollow}>Find Payment</Link>}
				description="Check Account Activity"
				actions={<Button variant="primary" onClick={loadHistory}><Icon name="search" /></Button>}
			>Account Activity</Header>
			</SpaceBetween>
		}
	>
			<Container
				header={
					<Header
						variant="h1"
						description=""
					>Transaction History</Header>
				}
			>
        <AccountList />
			</Container>
			
	</ContentLayout>

		</Layout>
	</>);
}