// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import React from 'react';
import TopNavigation from '@cloudscape-design/components/top-navigation';

export default function Menu() {
  return (<>
		<div id="top-nav">
			<TopNavigation
				identity={{
					logo: { src: "/aws-logo.png", alt: "AWS Logo" },
					title: "Amazon Money",
					href: "/"
				}}
				i18nStrings={{
					overflowMenuTriggerText: "More",
					overflowMenuTitleText: "All"
				}}
				utilities={[
					{
						type: "menu-dropdown",
						iconName: "settings",
						ariaLabel: "Settings",
						title: "Settings",
						items: [
							{ id: "settings-site", text: "Site Settings" },
							{ id: "settings-dashboard", text: "Dashboard Settings" }
						]
					},
					{
						type: "menu-dropdown",
						text: "About Me",
						description: "email@example.com",
						iconName: "user-profile",
						items: [
							{ id: "profile", text: "Profile" },
							{ id: "preferences", text: "Preferences" },
							{ id: "support-group", text: "Support", 
								items: [
									{ id: "documentation", text: "Documentation", href: "#", external: true, externalIconAriaLabel: " (opens in new tab)" },
									{ id: "feedback", text: "Feedback", href: "#", external: true, externalIconAriaLabel: " (opens in new tab)" }								
								]
							},
							{ id: "signout", text: "Sign Out" }
						]
					}
				]}
			/>
		</div>
	</>);
}