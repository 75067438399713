// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import React from 'react';
import { applyMode, Mode, applyDensity, Density } from '@cloudscape-design/global-styles';
import AppLayout, { AppLayoutProps } from '@cloudscape-design/components/app-layout';
import Menu from './menu';
import Breadcrumbs, { BreadcrumbsProps } from './breadcrumbs';
import Navigation from './navigation';
import Help from '../../components/help';

import './styles.css';

//applyMode(Mode.Dark);
applyMode(Mode.Light);
applyDensity(Density.Comfortable);
//applyDensity(Density.Compact);

export interface LayoutProps {
  children?: AppLayoutProps['content'];
  active?: BreadcrumbsProps['active'];
}

// expected properties
// props.children
// props.active
export function Layout({ children, active }: LayoutProps) {
  
  const [navigationState,setNavigationState] = React.useState(false);
  const [helpState,setHelpState] = React.useState(false);
  
	return(<>
<Menu />
<AppLayout 
		headerSelector="#top-nav"
		ariaLabels={{
			navigation: "Navigation drawer",
			navigationClose: "Close navigation drawer",
			navigationToggle: "Open navigation drawer",
			notifications: "Notifications",
			tools: "Help panel",
			toolsClose: "Close help panel",
			toolsToggle: "Open help panel"
		}}
		breadcrumbs={<Breadcrumbs active={active} />}
		navigation={<Navigation/>}
    navigationOpen={navigationState}
    onNavigationChange={(event) => {
      setNavigationState(!navigationState);
    }}
		tools={<Help/>}
    toolsOpen={helpState}
    onToolsChange={(event) => {
      console.log("payments/layout.onToolsChange - disabled");
      // setHelpState(!helpState);
    }}
		content={children}
/>
	</>);
}