// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import $ from 'jquery';
import { Amplify,Auth } from 'aws-amplify';
import awsconfig from './aws-amplify-exports';

const API2 = {
  configuration: "",
  // payment: "https://api.dev-rtr.fsi.pace.aws.dev/receipt-2",
  // Primary region
  payment: "https://vxhy3j7bb2.execute-api.us-east-1.amazonaws.com/receipt-2",
  // Secondary region
  // payment: "https://8eoxnz7iw5.execute-api.us-west-2.amazonaws.com/receipt-2",
	status: "https://so2ly3hal4.execute-api.us-east-1.amazonaws.com/dev/wallet-status",
	account: "https://so2ly3hal4.execute-api.us-east-1.amazonaws.com/dev/wallet-account",
  customer: "https://so2ly3hal4.execute-api.us-east-1.amazonaws.com/dev/wallet-customer",
	invite:  "TODO"
};

const USERNAME = "fsi-pace-amer+bofa-rtr-demo@amazon.com";
const PASSWORD = "x@ftLZk8";
const BOOTSTRAP = "https://so2ly3hal4.execute-api.us-east-1.amazonaws.com/dev/wallet-configuration";

let API = {
    // payment: "https://api.dev-rtr.fsi.pace.aws.dev/receipt-2",
  // Primary region
  //payment: "https://vxhy3j7bb2.execute-api.us-east-1.amazonaws.com/receipt-2",
  // Secondary region
  // payment: "https://8eoxnz7iw5.execute-api.us-west-2.amazonaws.com/receipt-2",
	//status: "https://so2ly3hal4.execute-api.us-east-1.amazonaws.com/dev/wallet-status",
	//account: "https://so2ly3hal4.execute-api.us-east-1.amazonaws.com/dev/wallet-account",
  //customer: "https://so2ly3hal4.execute-api.us-east-1.amazonaws.com/dev/wallet-customer",
	//invite:  "TODO",
  endpoints: {
    /*
    // payment: "https://api.dev-rtr.fsi.pace.aws.dev/receipt-2",
    // Primary Payment
    payment: "https://vxhy3j7bb2.execute-api.us-east-1.amazonaws.com/receipt-2",
    // Secondary Payment
    // payment: "https://8eoxnz7iw5.execute-api.us-west-2.amazonaws.com/receipt-2",
    status: "https://so2ly3hal4.execute-api.us-east-1.amazonaws.com/dev/wallet-status",
    account: "https://so2ly3hal4.execute-api.us-east-1.amazonaws.com/dev/wallet-account",
    customer: "https://so2ly3hal4.execute-api.us-east-1.amazonaws.com/dev/wallet-customer",  
    */
  },
  configure: async function () {
    console.log("configure AWS");
    Amplify.configure(awsconfig);

    console.log("authenticate UI");
    const au = await Auth.signIn(USERNAME, PASSWORD);
    console.log("retrieve configuration");
    $.ajax({
      url: BOOTSTRAP,
      type: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: au.signInUserSession.idToken.jwtToken          
      },
      success: function (data) {
        //console.log("API configuration data: " + JSON.stringify(data));
        let keys = Object.keys(data);
        for (const [key,value] of Object.entries(data)) {
          //console.log(`Key: ${key}, Value: ${value}`);
          API.endpoints[key] = value;
        }
        
        console.log("endpoints: " + JSON.stringify(API.endpoints));
      },
      error: function (data) {
        console.log("error: " + JSON.stringify(data));
      }
    });
  }
};



export default API;
