// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import React from 'react';
import { useNavigate } from 'react-router-dom';
import SideNavigation, { SideNavigationProps } from '@cloudscape-design/components/side-navigation';

const items: SideNavigationProps.Item[] = [
	{ type: "link", text: "Summary", href: "/wallet" },
	{ 
		type: "section", 
		text: "Payments",
		items: [
			{ type: "link", text: "Initiation", href: "/payment-initiation" },
			{ type: "link", text: "Status", href: "/payment-status" },
      { type: "link", text: "Activity", href: "/payment-activity" }
		]
	},
	{ 
		type: "section", 
		text: "Wallet",
    	defaultExpanded: false,
		items: [
			{ type: "link", text: "Identification", href: "/identification" },
			{ type: "link", text: "Messages", href: "/messages" },
			{ type: "link", text: "Accounts", href: "/accounts" },
			{ type: "link", text: "Preferences", href: "/preferences" },
		]
	},
	{ 
		type: "section", 
		text: "Platform",
    	defaultExpanded: false,
		items: [
			{ type: "link", text: "Health", href: "/platform-health" },
			{ type: "link", text: "Runbooks", href: "/platform-runbooks" },
		]
	},
]

export default function Navigation() {
  
  const navigate = useNavigate();
  const [activeHref,setActiveHref] = React.useState(window.location.pathname);
  
  function onFollow (event) {
    event.preventDefault();
    setActiveHref(event.detail.href);
    navigate(event.detail.href);
  }
  
  return (<>
		<SideNavigation
			activeHref={activeHref}
			header={{ href: "/", text: "Wallet" }}
			items={items}
      		onFollow={onFollow}
		/>
	</>);
}
