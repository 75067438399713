// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import $ from 'jquery';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
	ContentLayout,
	Container,
	Header,
	Link,
	Button,
	Alert,
	SpaceBetween,
	FormField,
	Form,
	Input,
	Box,
	Modal
} from "@cloudscape-design/components";
import { Amplify,Auth } from 'aws-amplify';
import awsconfig from '../aws-amplify-exports';
import API from '../endpoints';
import { SessionContext } from '../contexts/session';
import { Layout } from '../layouts/payments/layout';
import { CustomerProxy } from "../services/payment";

export function Invite (props) {

	const [user, setUser] = React.useState(null);	
	
	function handleInvitation () {
		//console.log("invite - " + user);
		setUser(null);
		
		var rqInvite = { email: user }
		
		$.post(API.endpoints["invite"], JSON.stringify(rqInvite), async function(rsInvite, status){
			console.log("rsInvite: " + JSON.stringify(rsInvite));
			props.dismiss();
		});		
	}
		
	return (<>
	<Modal 
		onDismiss={() => props.dismiss()}
		visible={props.display}
		footer={
     <Box float="right">
       <SpaceBetween direction="horizontal" size="xs">
         {/*<Button variant="link" onClick={() => setInvite(false)}>Cancel</Button>*/}
				 <Button variant="primary" onClick={handleInvitation}>Request</Button>
       </SpaceBetween>
     </Box>
		}
		header="Amazon Money Invitation"
	>
	<form><Form>
	<SpaceBetween direction="vertical" size="xs">
		<FormField
		  stretch="true"
			label="Email"
			//constraintText={"Username - "  + user}
		>
		  <Input
		    value={user}
		    onChange={event =>
		      setUser(event.detail.value)
		    }
		  />
		</FormField>
	</SpaceBetween>
	</Form></form>
	</Modal>
	</>);
}

export function Credentials () {
	return (<>
		<h1>Username / Password</h1>
	</>);
}

export function Login (props) {
	
	const navigate = useNavigate();

  const [user, setUser] = React.useState("");
	const [pass, setPass] = React.useState("");
	const [invite, setInvite] = React.useState(false);
	
	const session = React.useContext(SessionContext);

	async function handleLogin () {
		console.log("login");

		try {
      		//console.log("amplifyconfig: " + JSON.stringify(awsconfig));
    		//Amplify.configure(awsconfig);
			const au = await Auth.signIn(user, pass);

      		var proxy = new CustomerProxy();
      		await proxy.submit({
        		username: au.username,
        		success: async function (data) {
          			//console.log("customer-lookup success(data): " + JSON.stringify(data));
          			au.customer = data;
    				//console.log("authenticated user - " + JSON.stringify(au));
    				session.updateUser(au);
    				navigate("/");
        		},
        		error: function (data) {
          			console.log("customer-lookup error(data): " + JSON.stringify(data));
        		}
      		});
		}
		catch (error) {
			console.log("signIn error - " + error);
		}
	}
			
	return(<>
		
	<Container
		header={
			<Header
				variant="h2"
				description={session.user ? session.user.username : ""}
				actions={
		     <SpaceBetween direction="horizontal" size="xs">
		       <Button variant="link" onClick={() => setInvite(true)}>Request an Invite</Button>
					 <Button variant="primary" onClick={handleLogin}>Login</Button>			
		     </SpaceBetween>
				}
			>Welcome to Amazon Money</Header>
		}

	>
		<form><Form>
		<SpaceBetween direction="vertical" size="xs">
			<FormField
			  stretch="true"
				label="Username"
				//constraintText={"Username - "  + user}
			>
			  <Input
			    value={user}
			    onChange={event =>
			      setUser(event.detail.value)
			    }
			  />
			</FormField>
			<FormField
				stretch="true"
			  label="Password"
				//constraintText={"Password - "  + pass}
			>
			  <Input
					type="password"
			    value={pass}
			    onChange={event => setPass(event.detail.value)}
			  />
			</FormField>
		</SpaceBetween>
		</Form></form>
					
		<Invite display={invite} dismiss={() => setInvite(false)}/>

	</Container>
	</>);

}

export function Access() {
	
	function onLogin (token) {
		console.log("onAuthenticate - " + token);
	}
	
	return (<>
		
		<Layout active={{ text: "Access", href: "/access" }}>
		
		<ContentLayout
			header={
				<SpaceBetween size="m">

				</SpaceBetween>
			}
		>

			<Login onAuthenticate={onLogin}/>

		</ContentLayout>
				
		</Layout>
	</>);
}
