// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

"use strict";
import $ from 'jquery';
import { v4 as uuidv4 } from 'uuid';
import { Builder } from 'xml2js';
import { Auth } from 'aws-amplify';
import API from "../endpoints";
const TEMPLATE_PAIN00100106 = require('./pain00100106-template.json');

export class ConfigurationProxy {
  constructor() {
    
  }
  
  async submit ({success, error}) {
    console.log("ConfigurationProxy.submit");
    
    // authenticate
		//const au = await Auth.signIn("fsi-pace-amer+truist-zelle-demo@amazon.com", "GBZteCbj");
    const au = await Auth.signIn("fsi-pace-amer+bofa-rtr-demo@amazon.com", "x@ftLZk8");
    
    //console.log("idToken: " + au.signInUserSession.idToken.jwtToken);

    $.ajax({
      url: API.endpoints["configuration"],
      type: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: au.signInUserSession.idToken.jwtToken          
      },
      success: success,
      error: error
    });
  }
}

export class SubmissionProxy {
  constructor () {
    
  }
  
  async submit ({payment, success, error}) {
    console.log("submit payment: " + JSON.stringify(payment));

    // instruction
		var rqPayment = this.generatePAIN0010106(payment);
		//console.log("Pain0010106: " + rqPayment);
    
    // authenticate
		//const au = await Auth.signIn("fsi-pace-amer+truist-zelle-demo@amazon.com", "GBZteCbj");
    const au = await Auth.signIn("fsi-pace-amer+bofa-rtr-demo@amazon.com", "x@ftLZk8");
    //console.log("idToken: " + au.signInUserSession.idToken.jwtToken);
      
    $.ajax({
      url: API.endpoints["payment"],
      type: 'post',
      data: rqPayment,
      headers: {
         Authorization: au.signInUserSession.idToken.jwtToken,
         "Content-Type": "application/xml"     
      },
      success: success,
      error: error
    });
  }
  
  generatePAIN0010106 (payment) {
    
    let rq = JSON.parse(JSON.stringify(TEMPLATE_PAIN00100106));
    //console.log("template instance: " + JSON.stringify(rq));
    
    // Document / file
    rq.Document.CstmrCdtTrfInitn.GrpHdr.MsgId = payment.idCustomer;
    rq.Document.CstmrCdtTrfInitn.GrpHdr.CreDtTm = `${new Date().toISOString()}`;
    rq.Document.CstmrCdtTrfInitn.GrpHdr.InitgPty.Nm = `${payment.originatorFirstName} ${payment.originatorLastName}`;
    rq.Document.CstmrCdtTrfInitn.GrpHdr.InitgPty.PstlAdr.PstCd = payment.originatorPostal;
    rq.Document.CstmrCdtTrfInitn.GrpHdr.InitgPty.PstlAdr.TwnNm = payment.originatorCity;
    rq.Document.CstmrCdtTrfInitn.GrpHdr.InitgPty.PstlAdr.CtrySubDvsn = payment.originatorState;
    rq.Document.CstmrCdtTrfInitn.GrpHdr.InitgPty.PstlAdr.Ctry = payment.originatorCountry;
    rq.Document.CstmrCdtTrfInitn.GrpHdr.InitgPty.PstlAdr.AdrLine = payment.originatorAddress;
    rq.Document.CstmrCdtTrfInitn.GrpHdr.InitgPty.Id.Othr.Id = "10001165461134411222"; // TODO: customer-id
    rq.Document.CstmrCdtTrfInitn.GrpHdr.InitgPty.Id.Othr.SchmeNm.Cd = "CUST";
    rq.Document.CstmrCdtTrfInitn.GrpHdr.InitgPty.CtryOfRes = payment.originatorCountry;
    rq.Document.CstmrCdtTrfInitn.GrpHdr.InitgPty.CtctDtls.EmailAdr = payment.token;
    // PmtInf / batch
    rq.Document.CstmrCdtTrfInitn.PmtInf.PmtInfId = payment.idPmtInfId;
    rq.Document.CstmrCdtTrfInitn.PmtInf.ReqdExctnDt = payment.date;
    rq.Document.CstmrCdtTrfInitn.PmtInf.DbtrAcct.Id.Othr.Id = payment.originatorAccount.value;
    rq.Document.CstmrCdtTrfInitn.PmtInf.DbtrAgt.FinInstnId.ClrSysMmbId.MmbId = payment.originatorRouting;
    // CdtTrfTxInf / transaction
    rq.Document.CstmrCdtTrfInitn.PmtInf.CdtTrfTxInf.PmtId.EndToEndId = payment.idEndToEndId;
    rq.Document.CstmrCdtTrfInitn.PmtInf.CdtTrfTxInf.Amt.InstdAmt._ = payment.amount;
    rq.Document.CstmrCdtTrfInitn.PmtInf.CdtTrfTxInf.Amt.InstdAmt.$.Ccy = payment.currency;
    rq.Document.CstmrCdtTrfInitn.PmtInf.CdtTrfTxInf.CdtrAgt.FinInstnId.ClrSysMmbId.MmbId = payment.recipientRouting;
    rq.Document.CstmrCdtTrfInitn.PmtInf.CdtTrfTxInf.Cdtr.Nm = `${payment.recipientFirstName} ${payment.recipientLastName}`;
    rq.Document.CstmrCdtTrfInitn.PmtInf.CdtTrfTxInf.Cdtr.PstlAdr.PstCd = payment.recipientPostal;
    rq.Document.CstmrCdtTrfInitn.PmtInf.CdtTrfTxInf.Cdtr.PstlAdr.TwnNm = payment.recipientCity;
    rq.Document.CstmrCdtTrfInitn.PmtInf.CdtTrfTxInf.Cdtr.PstlAdr.CtrySubDvsn = payment.recipientState;
    rq.Document.CstmrCdtTrfInitn.PmtInf.CdtTrfTxInf.Cdtr.PstlAdr.AdrLine = payment.recipientAddress;
    switch (payment.recipientAlias) {
    case "phone":
      rq.Document.CstmrCdtTrfInitn.PmtInf.CdtTrfTxInf.CdtrAcct.Id.Othr.Id = payment.recipientPhone;
      rq.Document.CstmrCdtTrfInitn.PmtInf.CdtTrfTxInf.CdtrAcct.Id.Othr.SchmeNm.Prtry = "PHONE";
      break;
    case "email":
      rq.Document.CstmrCdtTrfInitn.PmtInf.CdtTrfTxInf.CdtrAcct.Id.Othr.Id = payment.recipientEmail;
      rq.Document.CstmrCdtTrfInitn.PmtInf.CdtTrfTxInf.CdtrAcct.Id.Othr.SchmeNm.Prtry = "EMAIL";
      break;
    default:
      rq.Document.CstmrCdtTrfInitn.PmtInf.CdtTrfTxInf.CdtrAcct.Id.Othr.Id = payment.recipientAccount;
      delete rq.Document.CstmrCdtTrfInitn.PmtInf.CdtTrfTxInf.CdtrAcct.Id.Othr.SchmeNm;
      break;
    }
    
    const builder = new Builder();
    const rqXML = builder.buildObject(rq);
    //console.log("pain001.001.06 request: " + rqXML);
    
    return(rqXML);
  }
  
  /*
  generatePAIN0010106xml (payment) {
  	var xml = `<Document xmlns="urn:iso:std:iso:20022:tech:xsd:pain.001.001.06">
  	<CstmrCdtTrfInitn>
  		<GrpHdr>
  			<MsgId>${payment.idCustomer}</MsgId>
  			<CreDtTm>${new Date().toISOString()}</CreDtTm>
  			<NbOfTxs>1</NbOfTxs>
  			<InitgPty>
          <Nm>${payment.originatorFirstName} ${payment.originatorLastName}</Nm>
          <PstlAdr>
            <PstCd>${payment.originatorPostal}</PstCd>
            <TwnNm>${payment.originatorCity}</TwnNm>
            <CtrySubDvsn>${payment.originatorState}</CtrySubDvsn>
            <Ctry>${payment.originatorCountry}</Ctry>
            <AdrLine>${payment.originatorAddress}</AdrLine>
          </PstlAdr>
          <Id>
            <Othr>
              <Id></Id>
              <SchmeNm><Cd>CUST</Cd></SchmeNm>
            </Othr>
          </Id>
          <CtryOfRes>${payment.originatorCountry}</CtryOfRes>
  				<CtctDtls>
  					<EmailAdr>${payment.token}</EmailAdr>
  				</CtctDtls>
  			</InitgPty>
  		</GrpHdr>
  		<PmtInf>
  			<PmtInfId>${payment.idPmtInfId}</PmtInfId>
  			<PmtMtd>TRF</PmtMtd>
        <PmtTpInf>
          <SvcLvl><Cd>INST</Cd></SvcLvl>
          <LclInstrm><Prtry>ZELLE</Prtry></LclInstrm>
          <CtgyPurp><Cd>EPAY</Cd></CtgyPurp>
        </PmtTpInf>
  			<ReqdExctnDt>${payment.date}</ReqdExctnDt>
  			<Dbtr>Y</Dbtr>
  			<DbtrAcct>
  				<Id>
  					<Othr><Id>${payment.originatorAccount.value}</Id></Othr>
  				</Id>
  			</DbtrAcct>
  			<DbtrAgt>
  				<FinInstnId><ClrSysMmbId><MmbId>${payment.originatorRouting}</MmbId></ClrSysMmbId></FinInstnId>
  			</DbtrAgt>
  			<CdtTrfTxInf>
  				<PmtId><EndToEndId>${payment.idEndToEndId}</EndToEndId></PmtId>
  				<Amt><InstdAmt Ccy="${payment.currency}">${payment.amount}</InstdAmt></Amt>
  				<CdtrAgt><FinInstnId><ClrSysMmbId><MmbId>${payment.recipientRouting}</MmbId></ClrSysMmbId></FinInstnId></CdtrAgt>
          <Cdtr>
            <Nm>${payment.recipientFirstName} ${payment.recipientLastName}</Nm>
            <PstlAdr>
              <PstCd>${payment.recipientPostal}</PstCd>
              <TwnNm>${payment.recipientCity}</TwnNm>
              <CtrySubDvsn>${payment.recipientState}</CtrySubDvsn>
              <Ctry>US</Ctry>
              <AdrLine>${payment.recipientAddress}</AdrLine>
            </PstlAdr>
            <CtryOfRes>US</CtryOfRes>
          </Cdtr>

  				<CdtrAcct>
            <Id>
              <Othr>
                <Id>+17775551212</Id><!-- or user@example.org || +17775551212 -->
                <SchmeNm><Prtry>PHONE</Prtry></SchmeNm><!-- or EMAIL || PHONE -->
              </Othr>
            </Id>
          </CdtrAcct>


  				<!--<CdtrAcct><Id><Othr><Id>${payment.recipientAccount}</Id></Othr></Id></CdtrAcct>-->
  			</CdtTrfTxInf>
  		</PmtInf>
  	</CstmrCdtTrfInitn>
  </Document>`;

  	return(xml);
  }
  */
}

export class StatusProxy {
  constructor() {
    
  }
  
  async submit ({search, success, error}) {
    console.log("StatusProxy.submit: " + JSON.stringify(search));
    
    // authenticate
		//const au = await Auth.signIn("fsi-pace-amer+truist-zelle-demo@amazon.com", "GBZteCbj");
    const au = await Auth.signIn("fsi-pace-amer+bofa-rtr-demo@amazon.com", "x@ftLZk8");
    
    //console.log("idToken: " + au.signInUserSession.idToken.jwtToken);
    
    const rqSearch = JSON.stringify(search);
    
    $.ajax({
      url: API.endpoints["status"],
      type: 'post',
      data: rqSearch,
      headers: {
        "Content-Type": "application/json",
        Authorization: au.signInUserSession.idToken.jwtToken          
      },
      success: success,
      error: error
    });
  }
}

export class CustomerProxy {
  constructor() {
    
  }
  
  async submit({username, success, error}) {
    console.log("CustomerProxy.submit: " + JSON.stringify(username));
    
    const customer = {
      id: "07095161-322d-4b0e-be2c-b083a4fb4421",
      token: "bcstgn@amazon.com",
      name: {
        first: "Brandon",
        last: "Castagna"
      },
      address: {
        street1: "4533 Noras Path Road",
        postal: "28226",
        city: "Charlotte",
        state: "NC",
        country: "US"
      },
      accounts: [
        { name: "Accounts Receivable", routing: "34567890", account: "123456789012", currency: "USD" },
        { name: "Accounts Payable", routing: "34567890", account: "978675645342", currency: "USD" },
        { name: "Cash", routing: "99998888", account: "239718050810", currency: "USD" }          
      ]
    };
    
    // authenticate
		//const au = await Auth.signIn("fsi-pace-amer+truist-zelle-demo@amazon.com", "GBZteCbj");
    const au = await Auth.signIn("fsi-pace-amer+bofa-rtr-demo@amazon.com", "x@ftLZk8");
    console.log("idToken: " + au.signInUserSession.idToken.jwtToken);
    
    const query = {
      username: username
    };
    const rqQuery = JSON.stringify(query);
    
    $.ajax({
      url: API.endpoints["customer"],
      type: 'post',
      data: rqQuery,
      headers: {
        "Content-Type": "application/json",
        Authorization: au.signInUserSession.idToken.jwtToken          
      },
      success: success,
      error: error
    });
    
    //success(customer);
  }
}

export class AccountProxy {
  constructor() {
    
  }
  
  async submit({lookup, success, error}) {
    console.log("AccountsProxy.submit: " + JSON.stringify(lookup));
    
    // authenticate
		//const au = await Auth.signIn("fsi-pace-amer+truist-zelle-demo@amazon.com", "GBZteCbj");
    const au = await Auth.signIn("fsi-pace-amer+bofa-rtr-demo@amazon.com", "x@ftLZk8");
    console.log("idToken: " + au.signInUserSession.idToken.jwtToken);
    
    const rqLookup = JSON.stringify(lookup);
    
    $.ajax({
      url: API.endpoints["account"],
      type: 'post',
      data: rqLookup,
      headers: {
        "Content-Type": "application/json",
        Authorization: au.signInUserSession.idToken.jwtToken     
      },
      success: success,
      error: error
    });
  }
}

