// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import React from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link,
	Redirect,
	Navigate
} from "react-router-dom";
import { 
	Header,
	Container,
	SpaceBetween,
	Input,
	Button
} from "@cloudscape-design/components";
import "@cloudscape-design/global-styles/index.css";
import API from './endpoints';

import './App.css';

import Layout from './layouts/payments/layout';
import { SessionContext } from './contexts/session'
import { Access } from './components/access';
import { PaymentRecipient } from './pages/payment-recipient/recipient';
import { PaymentInitiation } from './pages/payment-initiation/initiation';
import { PaymentStatus } from './pages/payment-status/status';
import { PaymentActivity } from './pages/payment-activity/activity';
import { PaymentHome } from './pages/payment-home';
import { Wallet } from './pages/wallet';
import { InvitationStatus } from './pages/invitation';
import { PlatformHealth } from './pages/platform-health/health';
import { PlatformRunbooks } from './pages/platform-runbooks/runbooks';

function AuthorizedRoutes () {
  return(<>
		<Routes>
			<Route path="/payment-initiation" element={<PaymentInitiation/>} />
			<Route path="/payment-status" element={<PaymentStatus/>} />
      		<Route path="/payment-activity" element={<PaymentActivity/>} />
			<Route path="/wallet" element={<Wallet/>} />
			<Route path="/invitation/:id" element={<InvitationStatus/>} />
			<Route path="/platform-health" element={<PlatformHealth/>} />
			<Route path="/platform-runbooks" element={<PlatformRunbooks/>} />
			<Route path="/" element={<Navigate to='/payment-initiation' />} />
			<Route path="*" element={<Navigate to='/' />} />
		</Routes>
  </>);
}

function UnauthorizedRoutes () {
	return(<>
		<Routes>
			<Route path="/access" element={<Access/>} />
			<Route path='/' element={<Navigate to='/access' />} />
			<Route path='*' element={<Navigate to='/access' />} />
		</Routes>
	</>);
}

function App() {
  const session = React.useContext(SessionContext);
  //console.log("session.username: " + JSON.stringify(session.user.username));
  
  API.configure();

  return (<>
		<Router>{session.user.username ? <AuthorizedRoutes/> : <UnauthorizedRoutes/>}</Router>
	</>);
  /*
  return (<>
		<Router><AuthorizedRoutes/></Router>
	</>);
  */
}

export default App;
