// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import React from 'react';

export const createDefaultUser = () => {
	
	return({
		user: { username: "" },
		updateUser: (props) => {
			console.warn("updateUserDefault");
		}
	});
};

export const SessionContext = React.createContext(createDefaultUser());
export const SessionProvider = (props) => {
	const [user,setUser] = React.useState({ username: null });
	console.log("access.SessionProvider - " + JSON.stringify(user.username));
	
	return(<>
		<SessionContext.Provider value={ {user, updateUser: setUser} }>
			{props.children}
		</SessionContext.Provider>
	</>);
}
