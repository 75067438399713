// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import React from 'react';
import { 
	Box,
  Grid,
  FormField,
  Select
} from "@cloudscape-design/components";
import { format,parseISO } from 'date-fns';
import { SessionContext } from '../contexts/session';
import { AccountProxy } from "../services/payment";
import './transactions.css';

function localMoney(currency, value) {
	//console.log("localMoney.currency - " + currency);
	//console.log("currency = 'USD' - " + (currency == 'USD'));
	var amount = "";
  /*
  let txt = (new Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(value));
  amount = <div style={{textAlign: "right"}}>{txt}</div>;
	*/
  amount = (new Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(value));
  
	//console.log("final amount: " + amount);

	return(amount);
}

function localDate (string) {
	return(format(parseISO(string), "MMMM dd, yyyy hh:mm:ssaaaaa'm'"));
}

function loadActivity (routing, account) {
  console.log("load activity: " + routing + ":" + account);
	
	var rqLookup = { 
		//id: session.user.customer.id,
		//token: session.user.customer.token,
    routing: routing,
    account: account
	};
  
  var proxy = new AccountProxy();
  proxy.submit({
    lookup: rqLookup,
    success: async function (data) {
      console.log("account-lookup success");
      //console.log("success(data): " + JSON.stringify(data));
      if (data.length > 0) {
        console.log("transactions: " + data.length);
        //setHistory(data);          
      }
      
    },
    error: function (data) {
      console.log("account-lookup error");
      console.log("error(data): " + JSON.stringify(data));
    }
  });
}

function setAccount (acct) {
  console.log("set account: " + JSON.stringify(acct));
  loadActivity(acct.routing, acct.value);
}

function AccountBalance ({txn}) {
  
/*
  return (<>
    <h1 className="transaction-account-balance">{ txn != null ? localMoney(txn.currency, txn.balance) : "$0.00" }</h1>
  </>);
*/
  return (<>
    <div className="transaction-account-balance">
      <span>Current Balance</span>
      <span className="transaction-account-amount">{ txn != null ? localMoney(txn.currency, txn.balance) : "$0.00" }</span>
    </div>
  </>);
}

function TransactionTitle ({txn}) {
  let routing = null;
  let account = null;

  //let header = <h1>No History</h1>;
  let header = <h1></h1>;

  if (txn != null) {
    routing = txn.routing;
    //account = txn.account.replace(/^.{8}/, "********");
    account = txn.account.replace(/^.{8}/, "\u25CF\u25CF\u25CF\u25CF\u25CF\u25CF\u25CF\u25CF");
    header = <h2>Account: {routing}-{account}</h2>;
  }

  return (<>
    {header}
  </>);
}

function TransactionRow ({txn, dci}) {
  
  let localTimestamp = localDate(txn.timestamp);
  let localBalance = localMoney(txn.currency, txn.balance);
  let localAmount = localMoney(txn.currency, txn.amount);
  
  return (<>
    <Box key={txn.id}>
      <Grid gridDefinition={[{colspan: 1}, {colspan: 4}, {colspan: 2}, {colspan: 4}, {colspan:1}]}>
      <span></span>
      <span>{localTimestamp}</span>
      <span></span>
      <div className={dci}>{localAmount}</div>
      <span></span>
      </Grid>
    </Box>
  </>);
}

export function TransactionList({transactions}) {
  let rows = [];
  for (let i = 0; i < transactions.length; i++) {
    let dci = "transaction-credit";
    if (transactions[i].amount < 0) {
      dci = "transaction-debit";
    }
    rows.push(<TransactionRow key={transactions[i].id} txn={transactions[i]} dci={dci} />);
  }
  
	return (<>
      <div>
        <TransactionTitle txn={transactions[0]} />
        <AccountBalance txn={transactions[0]} />
        {rows}
      </div>
  </>);
}

function AccountSelector ({onSelection}) {
  
	const session = React.useContext(SessionContext);
  
  const accounts = [];
  for (let i = 0; i < session.user.customer.accounts.length; i++) {
    let account = session.user.customer.accounts[i];
    //let mask = account.account.replace(/^.{8}/, "********");
    let mask = account.account.replace(/^.{8}/, "\u25CF\u25CF\u25CF\u25CF\u25CF\u25CF\u25CF\u25CF");
    accounts.push({
      label: `${mask} - ${account.name}`,
      value: `${account.account}`,
      currency: `${account.currency}`,
      routing: `${account.routing}`
    });
  }
  
  const [activeAccount, setActiveAccount] = React.useState(accounts[0]); 
  //setActiveAccount(accounts[0]);
  //onSelection(accounts[0]);
  
  function selectAccount (acct) {
    setActiveAccount(acct);
    onSelection(acct);
  }

  React.useEffect(() => {
    console.log("AccountSelector.useEffect");
    onSelection(accounts[0]);
  }, []);
  
  return (<>
      <Grid gridDefinition={[{colspan: 6}, {colspan: 6}]}>
        <Box></Box>
        <FormField label="Account" stretch={true}>
          <Select
            selectedOption={activeAccount}
            onChange={({ detail }) =>
              //console.log(detail.selectedOption)
              selectAccount(detail.selectedOption)
            }
            options={accounts}
        		placeholder="Select an account"
          />
        </FormField>
    </Grid>
  </>);
}

export function AccountList () {
  const [history, setHistory] = React.useState([]);
  
  function loadActivity (acct) {
    console.log("loadActivity: " + JSON.stringify(acct));

  	var rqLookup = { 
  		//id: session.user.customer.id,
  		//token: session.user.customer.token,
      routing: acct.routing,
      account: acct.value
  	};
  
    var proxy = new AccountProxy();
    proxy.submit({
      lookup: rqLookup,
      success: async function (data) {
        console.log("account-lookup success");
        //console.log("success(data): " + JSON.stringify(data));
        if (data.length > 0) {
          console.log("transactions: " + data.length);
          setHistory(data);
        }
        else {
          setHistory([]);
        }
      },
      error: function (data) {
        console.log("account-lookup error");
        console.log("error(data): " + JSON.stringify(data));
      }
    });
  }
    
  return(<>
    <AccountSelector onSelection={loadActivity} />
    <TransactionList transactions={history} />
  </>);
}