// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

/*
const config = {
		Auth: {
			region: '###AWS_DEFAULT_REGION###',
			userPoolId: '###SECRET_USER_POOL_ID###',
			userPoolWebClientId: '###SECRET_WEB_CLIENT_ID###',
      		identityPoolId: '###SECRET_IDENTITY_POOL_ID###',
      		authenticationFlowType: 'USER_PASSWORD_AUTH',
		}
};
*/

/* Zelle
const config = {
		Auth: {
			region: 'us-east-1',
			userPoolId: 'us-east-1_GZdj8es7g',
			userPoolWebClientId: '55ge1r2i6n5h8p2va8aukdme83',
      identityPoolId: 'us-east-1:81f71e24-785d-4d4e-835f-d57ce2aae714',
      authenticationFlowType: 'USER_PASSWORD_AUTH',
		}
};
*/

/* RTR */
const config = {
	Auth: {
		region: 'us-east-1',
		userPoolId: 'us-east-1_KFafVliqU',
		userPoolWebClientId: '2ojps76e46qi23ieieubruq3lo',
      	identityPoolId: 'us-east-1:58999bd5-6646-4a73-8001-971fd56a0820',
      	authenticationFlowType: 'USER_PASSWORD_AUTH',
	}
};

export default config;
