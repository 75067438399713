////////////////////////////////////////////////////////////////////////////////
// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/
////////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { 
	ContentLayout,
	Container,
	Header,
	Link,
	Button,
	Alert,
	SpaceBetween
} from "@cloudscape-design/components";
import { Layout } from '../../layouts/payments/layout';
import { SessionContext } from '../../contexts/session';

export function PlatformHealth({ user }) {

    const session = React.useContext(SessionContext);

	//const [token,setToken] = React.useState(user);
    const [token,setToken] = React.useState(session.user.username);

	function onLogin (token) {
		console.log("onAuthenticate - " + token);
		setToken(token);
	}

	return (<>
		<Layout active={{ text: "Platform Health", href: "/platform-health" }}>

			<Container
				header={
					<Header
						variant="h2"
						description="Operational Status Reporting"
					>Platform Health</Header>
				}
			>
                User: {token}
			</Container>

		</Layout>
	</>);
}