// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import $ from 'jquery';
import React from 'react';
import { useParams } from 'react-router-dom';
import { 
	ContentLayout,
	Container,
	Header,
	Link,
	Button,
	Alert,
	SpaceBetween,
	Icon,
	FormField,
	Input,
	Box
} from "@cloudscape-design/components";
import { Layout } from '../layouts/payments/layout';
import API from "../endpoints";

export function Wallet() {

	return (<>
		<Layout active={{ text: "Welcome", href: "/payment-status" }}>

	<ContentLayout
		header={
			<SpaceBetween size="m">
			<Header 
				variant="h1"
				//info={<Link href="/payment-initiation">Send Payment</Link>}
				description="Create your Wallet"
				//actions={<Button variant="primary" onClick={activate}><Icon name="search" /></Button>}
			>Invitation</Header>
			</SpaceBetween>
		}
	>
			<Container
				header={
					<Header
						variant="h1"
						description=""
					>Welcome to Amazon Money</Header>
				}
			>
				<SpaceBetween direction="vertical" size="xxl">
				<h1>Invitation</h1>
				</SpaceBetween>
			</Container>
			
	</ContentLayout>

		</Layout>
	</>);
}