// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import $ from 'jquery';
import React from 'react';
import { useParams } from 'react-router-dom';
import { 
	ContentLayout,
	Container,
	Header,
	Link,
	Button,
	Alert,
	SpaceBetween,
	Icon,
	FormField,
	Input,
	Box,
	Grid,
	ExpandableSection,
  FileUpload
} from "@cloudscape-design/components";
import { Layout } from '../layouts/payments/layout';
import API from "../endpoints";

export function InvitationStatus() {
  
  const infoBasic = {
    invitationID: null,
    email: null,
    firstName: null,
    lastName: null,
    phone: null,
    passwordNew: null,
    passwordConfirmation: null
  };
  
  const infoExtended = {
    address1: null,
    address2: null,
    city: null,
    state: null,
    postal: null,
    dateOfBirth: null,
    taxID: null,
    country: null,
    governmentID: null
  };
  
  const infoAdvanced = {
    
  };


	function activate () {
		//console.log("activate");
    console.log("basic information: " + JSON.stringify(infoBasic));
    console.log("extended information: " + JSON.stringify(infoExtended));
    console.log("advanced information: " + JSON.stringify(infoAdvanced));
	}

	return(<>
		<Layout active={{ text: "Welcome", href: "/payment-status" }}>

	<ContentLayout
		header={
			<SpaceBetween size="m">
			<Header 
				variant="h1"
				//info={<Link href="/payment-initiation">Send Payment</Link>}
				description="Create your Wallet"
				actions={<Button variant="primary" onClick={activate}><Icon name="thumbs-up" /> Sign Up</Button>}
			>Welcome to Amazon Money</Header>
			</SpaceBetween>
		}
	>
    <SpaceBetween direction="vertical" size="xl">
      <RegistrationInformationBasic information={infoBasic} />
      <RegistrationInformationExtended information={infoExtended} />
      <RegistrationInformationAdvanced information={infoAdvanced} />
    </SpaceBetween>
	</ContentLayout>

		</Layout>
	</>);
}

export function RegistrationInformationBasic ({information}) {

	const { id } = useParams();
	const [email, setEmail] = React.useState(null);
	const [firstName, setFirstName] = React.useState(null);
	const [lastName, setLastName] = React.useState(null);
	const [phone, setPhone] = React.useState(null);
	const [passwordNew, setPasswordNew] = React.useState(null);
	const [passwordConfirmation, setPasswordConfirmation] = React.useState(null);
  
  React.useEffect(() => {
    information.invitationID = id;
    information.email = email;
    information.firstName = firstName;
    information.lastName = lastName;
    information.phone = phone;
    information.passwordNew = passwordNew;
    information.passwordConfirmation = passwordConfirmation;
  });
  
  return(<>
	<Container
		header={
			<Header
				variant="h1"
				description="Required - Help us get to know you"
			>Registration</Header>
		}
	>
	<SpaceBetween direction="vertical" size="xxl">
	<SpaceBetween direction="vertical" size="s">
	  <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 } ]}>
  		<FormField label="Email" stretch={true}>
  			<Input
  				value={email}
  				onChange={({detail}) => setEmail(detail.value) }
  				placeholder="Confirm Invitation Email"
  			/>
  		</FormField>
  		<FormField label="Invitation" stretch={true}>
  			<Input
  				value={id}
  				readOnly={true}
  			/>
  		</FormField>
  	</Grid>
  	<Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
  		<FormField label="First Name" stretch={true}>
  			<Input
  				value={firstName}
  				onChange={({detail}) => setFirstName(detail.value)}
  			/>
  		</FormField>
  		<FormField label="Last Name" stretch={true}>
  			<Input
  				value={lastName}
  				onChange={({detail}) => setLastName(detail.value)}
  			/>
  		</FormField>
  		<FormField label="Phone" stretch={true}>
  			<Input
  				value={phone}
  				onChange={({detail}) => setPhone(detail.value)}
  			></Input>
  		</FormField>		
  	</Grid>
  	<Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 } ]}>
  		<FormField label="Password" stretch={true}>
  			<Input
  				value={passwordNew}
  				onChange={({detail}) => setPasswordNew(detail.value) }
  				placeholder="New Password"
  				type="password"
  			/>
  		</FormField>
  		<FormField label="Confirmation" stretch={true}>
  			<Input
  				value={passwordConfirmation}
  				onChange={({detail}) => setPasswordConfirmation(detail.value) }
  				placeholder="Confirm Password"
  				type="password"
  			/>
  		</FormField>
  	</Grid>
	</SpaceBetween>
	<Box>
	  <Header variant="h2">Agreements</Header>
		<ExpandableSection headerText="Terms and Conditions">
			No one really reads these...
		</ExpandableSection>
		<ExpandableSection headerText="Privacy Policy">
			Well, except you - impressive!
		</ExpandableSection>
	</Box>
	</SpaceBetween>
	</Container>
  
  </>);
}

export function RegistrationInformationExtended ({information}) {
  
	const [address1, setAddress1] = React.useState(null);
	const [address2, setAddress2] = React.useState(null);
	const [address3, setAddress3] = React.useState(null);
	const [address4, setAddress4] = React.useState(null);
	const [city, setCity] = React.useState(null);
	const [state, setState] = React.useState(null);
	const [postal, setPostal] = React.useState(null);
	const [country, setCountry] = React.useState(null);

	React.useEffect(() => {
    information.address1 = address1;
    information.address2 = address2;
    information.address3 = address3;
    information.address4 = address4;
    information.city = city;
    information.state = state;
    information.postal = postal;
    information.country = country;
	});

	return(<>
	<Container
		header={
			<Header
				variant="h1"
				description="Optional - needed to transact over $100 per day"
			>Additional Information</Header>
		}
	>
			<SpaceBetween direction="vertical" size="xxl">
        <SpaceBetween direction="vertical" size="s">
          <Grid gridDefinition={[ { colspan: 3 }, { colspan: 3 }, { colspan: 3 }, { colspan: 3 } ]}>
          	<FormField label="Address" stretch={true}>
          		<Input
          			value={address1}
          			onChange={({detail}) => setAddress1(detail.value) }
          			placeholder="Address #1"
          		/>
          	</FormField>
          	<FormField label="&nbsp;" stretch={true}>
          		<Input
          			value={address2}
                onChange={({detail}) => setAddress2(detail.value) }
                placeholder="Address #2"
          		/>
          	</FormField>
          	<FormField label="&nbsp;" stretch={true}>
          		<Input
          			value={address3}
                onChange={({detail}) => setAddress3(detail.value) }
                placeholder="Address #3"
          		/>
          	</FormField>
          	<FormField label="&nbsp;" stretch={true}>
          		<Input
          			value={address4}
                onChange={({detail}) => setAddress4(detail.value) }
                placeholder="Address #4"
          		/>
          	</FormField>
          </Grid>
          <Grid gridDefinition={[ { colspan: 4 }, { colspan: 3 }, { colspan: 3 }, { colspan: 2 } ]}>
          	<FormField label="City" stretch={true}>
          		<Input
          			value={city}
                onChange={({detail}) => setCity(detail.value) }
                placeholder="City"
          		/>
          	</FormField>
          	<FormField label="State" stretch={true}>
          		<Input
          			value={state}
                onChange={({detail}) => setState(detail.value) }
                placeholder="State"
          		/>
          	</FormField>
          	<FormField label="Postal Code" stretch={true}>
          		<Input
          			value={postal}
                onChange={({detail}) => setPostal(detail.value) }
                placeholder="Postal Code"
          		/>
          	</FormField>
          	<FormField label="Country" stretch={true}>
          		<Input
          			value={country}
                onChange={({detail}) => setCountry(detail.value) }
                placeholder="US"
          		/>
          	</FormField>
          </Grid>
        
        </SpaceBetween>
      </SpaceBetween>
  </Container>
  </>);
}

export function RegistrationInformationAdvanced({information}) {

	const [dateOfBirth, setDateOfBirth] = React.useState(null);
	const [governmentID, setGovernmentID] = React.useState(null);
	const [taxID, setTaxID] = React.useState(null);
  const [governmentIDFront, setGovernmentIDFront] = React.useState([]);

	React.useEffect(() => {
    information.dateOfBirth = dateOfBirth;
    information.governmentID = governmentID;
    information.taxID = taxID;
    information.governmentIDFront = governmentIDFront;
	});
  
	return(<>
	<Container
		header={
			<Header
				variant="h1"
				description="Optional - needed to transact over $10,000 per day"
			>Legal Customer Due Diligence</Header>
		}
	>
			<SpaceBetween direction="vertical" size="xxl">
        <SpaceBetween direction="vertical" size="s">
          <Grid gridDefinition={[ { colspan: 4 }, { colspan: 4 }, { colspan: 4 } ]}>
          <FormField label="Date of Birth" stretch={true}>
          	<Input
          		value={dateOfBirth}
          		onChange={({detail}) => setDateOfBirth(detail.value) }
          		placeholder="YYYY/MM/DD"
          	/>
          </FormField>
        	<FormField label="Tax ID" stretch={true}>
        		<Input
        			value={taxID}
              onChange={({detail}) => setTaxID(detail.value) }
              placeholder="xxx-yy-zzzz"
        		/>
        	</FormField>
        	<FormField label="Government ID" stretch={true}>
        		<Input
        			value={governmentID}
              onChange={({detail}) => setGovernmentID(detail.value) }
              placeholder="Driver's License or Passport"
        		/>
        	</FormField>
          </Grid>
          <Grid gridDefinition={[ { colspan: 6 }, { colspan: 6 } ]}>
              
            <FormField label="Government ID (Front)" stretch={true}>
              <FileUpload 
                value={governmentIDFront}
                onChange={({detail}) => setGovernmentIDFront(detail.value)}
                showFileLastModified
                showFileSize
                showFileThumbnail
                tokenLimit={3}
                constraintText="Upload image of front of government issued ID"
                i18nStrings={{
                  uploadButtonText: e => e ? "Choose files" : "Choose file",
                  dropzoneText: e => e ? "Drop files to upload" : "Drop file to upload",
                  removeFileAriaLabel: e => `Remove file ${e + 1}`,
                  limitShowFewer: "Show fewer files",
                  limitShowMore: "Show more files",
                  errorIconAriaLabel: "Error"
                }}
              />
            </FormField>
            <FormField label="Government ID (Back)" stretch={true}>
              <FileUpload 
                value={governmentIDFront}
                onChange={({detail}) => setGovernmentIDFront(detail.value)}
                showFileLastModified
                showFileSize
                showFileThumbnail
                tokenLimit={3}
                constraintText="Upload image of back of government issued ID"
                i18nStrings={{
                  uploadButtonText: e => e ? "Choose files" : "Choose file",
                  dropzoneText: e => e ? "Drop files to upload" : "Drop file to upload",
                  removeFileAriaLabel: e => `Remove file ${e + 1}`,
                  limitShowFewer: "Show fewer files",
                  limitShowMore: "Show more files",
                  errorIconAriaLabel: "Error"
                }}
              />
            </FormField>
          </Grid>
        </SpaceBetween>
      </SpaceBetween>
  </Container>
  </>);
}