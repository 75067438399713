// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import $ from 'jquery';
import React from 'react';
import { 
	ContentLayout,
	Container,
	Header,
	Link,
	Button,
	Alert,
	SpaceBetween,
	Icon,
	FormField,
	Input,
	Box
} from "@cloudscape-design/components";
import { SessionContext } from '../../contexts/session';
import { Layout } from '../../layouts/payments/layout';
//import { Login1,Login2,Login3 } from "../../components/login";
import { PaymentDetails } from "../../components/payments";
import { StatusProxy } from "../../services/payment";
import API from "../../endpoints";

export function PaymentStatus() {

	const session = React.useContext(SessionContext);

	const [token,setToken] = React.useState(session.user.username);
	const [customerID, setCustomerID] = React.useState(null);
	const [payment, setPayment] = React.useState(null);

	function onLogin (token) {
		console.log("onAuthenticate - " + token);
		setToken(token);
	}
	
	function searchPayment () {
		console.log("searchPayment - " + token + " / " + customerID);
		
		var rqSearch = { 
			idCustomer: customerID,
			tokenUser: token
		};
    
    var proxy = new StatusProxy();
    proxy.submit({
      search: rqSearch,
      success: async function (data) {
        console.log("payment-search success");
        console.log("success(data): " + JSON.stringify(data));
        if (data.length > 0) {
          let payment = data[0];
          console.log("payment object: " + JSON.stringify(payment));
          setPayment(payment);          
        }
      },
      error: function (data) {
        console.log("payment-initiation error");
        console.log("error(data): " + JSON.stringify(data));
      }
    });
    
		/*
    $.post(API.status, JSON.stringify(rqSearch), function(rsSearch, status){
				setPayment(rsSearch);
				//console.log("payment: " + JSON.stringify(rsSearch));
		});
    */
	}

	return (<>
		<Layout active={{ text: "Status", href: "/payment-status" }}>

	<ContentLayout
		header={
			<SpaceBetween size="m">
			<Header 
				variant="h1"
				info={<Link href="/payment-initiation">Send Payment</Link>}
				description="Check Payment Status"
				actions={<Button variant="primary" onClick={searchPayment}><Icon name="search" /></Button>}
			>Payment Status</Header>
			</SpaceBetween>
		}
	>
			<Container
				header={
					<Header
						variant="h1"
						description=""
					>Search Payments</Header>
				}
			>
			{/*}<Login3 onAuthenticate={onLogin}/>*/}
				<SpaceBetween direction="vertical" size="xxl">
				<SpaceBetween direction="vertical" size="s">
				<FormField label="User" stretch={true}>
					<Input
						value={token}
						onChange={({detail}) => setToken(detail.value)}
					/>
				</FormField>
				<FormField label="Payment Reference" stretch={true}>
					<Input
						value={customerID}
						onChange={({detail}) => setCustomerID(detail.value)}
					/>
				</FormField>
				</SpaceBetween>
				<PaymentDetails payment={payment} />
				</SpaceBetween>
			</Container>
			
	</ContentLayout>

		</Layout>
	</>);
}