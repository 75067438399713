// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import React from 'react';
import { 
	Container,
	Button,
	Header,
	Form,
	FormField,
	Input,
	Select,
	DatePicker,
	DateInput,
	RadioGroup,
	Toggle,
	Tiles,
	SpaceBetween,
	Modal,
	Box,
	Grid,
	ColumnLayout
} from "@cloudscape-design/components";
import { v4 as uuidv4 } from 'uuid';
import { format,parseISO } from 'date-fns';
import { SessionContext } from '../contexts/session';
import './payments.css';

function localMoney(currency, value) {
	//console.log("localMoney.currency - " + currency);
	//console.log("current = 'USD' - " + (currency == 'USD'));
	var amount = "";
	switch (currency) {
	case 'USD':
		///console.log("case USD");
		amount = "$ " + value;
		break;
	default: 
		//console.log("case default");
		amount = value + " " + currency;
		break;
	}
	
	//console.log("final amount: " + amount);

	return(amount);
}

function localDate (string) {
	return(format(parseISO(string), "MMMM dd, yyyy"));
}

function generateUUID () {
	var uuid = uuidv4();
	uuid = uuid.replace(/-/g, "").toUpperCase();
	return(uuid);
}

export function PaymentDetails (props) {
	
	/*
	if (props.payment) {
		console.log("payment: " + JSON.stringify(props.payment));
		console.log("amount: " + props.payment.CdtTrfTxInf.data.CdtTrfTxInf.Amt.InstdAmt._);
		console.log("currency: " + props.payment.CdtTrfTxInf.data.CdtTrfTxInf.Amt.InstdAmt.$.Ccy);
	}
  else {
    console.log("props: " + JSON.stringify(props));
  }
	
	const [date, setDate] = 
		React.useState(props.payment ? localDate(props.payment.PmtInf.data.PmtInf.ReqdExctnDt) : null);
	const [amount, setAmount] = 
		React.useState(props.payment ? localMoney(props.payment.CdtTrfTxInf.data.CdtTrfTxInf.Amt.InstdAmt.$.Ccy, props.payment.CdtTrfTxInf.data.CdtTrfTxInf.Amt.InstdAmt._) : null);
	
	const [originatorAccount, setOriginatorAccount] = 
		React.useState(props.payment ? props.payment.PmtInf.data.PmtInf.DbtrAcct.Id.Othr.Id : null);
	const [originatorRouting, setOriginatorRouting] = 
		React.useState(props.payment ? props.payment.PmtInf.data.PmtInf.DbtrAgt.FinInstnId.ClrSysMmbId.MmbId : null);
	const [recipientAccount, setRecipientAccount] = 
		React.useState(props.payment ? props.payment.CdtTrfTxInf.data.CdtTrfTxInf.CdtrAcct.Id.Othr.Id : null);
	const [recipientRouting, setRecipientRouting] = 
		React.useState(props.payment ? props.payment.CdtTrfTxInf.data.CdtTrfTxInf.CdtrAgt.FinInstnId.ClrSysMmbId.MmbId : null);

	const [idCustomer, setIDCustomer] = 
		React.useState(props.payment ? props.payment.Document.data.Document.CstmrCdtTrfInitn.GrpHdr.MsgId : null);
	const [idPayment, seIDPayment] = 
		React.useState(props.payment ? props.payment.PmtInf.data.PmtInf.PmtInfId : null);
	const [idEndToEnd, setIDEndToEnd] = 
		React.useState(props.payment ? props.payment.CdtTrfTxInf.data.CdtTrfTxInf.PmtId.EndToEndId : null);
	*/

	var resultPayment = <>
	<SpaceBetween direction="vertical" size="xxl">
	<Box>
		<Box textAlign="left"><h1>Payment Details</h1></Box>
		<ColumnLayout columns={2}>
			<Box><SpaceBetween direction="vertical" size="l">
				<FormField label="Date" stretch={true}><div className="payment-field">{props.payment ? localDate(props.payment.PmtInf.data.PmtInf.ReqdExctnDt) : null}</div></FormField>
				<FormField label="Originator Account" stretch={true}><div className="payment-field">{props.payment ? props.payment.PmtInf.data.PmtInf.DbtrAcct.Id.Othr.Id : null}</div></FormField>
				<FormField label="Originator Routing Code" stretch={true}><div className="payment-field">{props.payment ? props.payment.PmtInf.data.PmtInf.DbtrAgt.FinInstnId.ClrSysMmbId.MmbId : null}</div></FormField>
			</SpaceBetween></Box>
			<Box><SpaceBetween direction="vertical" size="l">
				<FormField label="Amount" stretch={true}><div className="payment-field">{props.payment ? localMoney(props.payment.CdtTrfTxInf.data.CdtTrfTxInf.Amt.InstdAmt.$.Ccy, props.payment.CdtTrfTxInf.data.CdtTrfTxInf.Amt.InstdAmt._) : null}</div></FormField>		
				<FormField label="Recipient Account" stretch={true}><div className="payment-field">{props.payment ? props.payment.CdtTrfTxInf.data.CdtTrfTxInf.CdtrAcct.Id.Othr.Id : null}</div></FormField>
				<FormField label="Recipient Routing Code" stretch={true}><div className="payment-field">{props.payment ? props.payment.CdtTrfTxInf.data.CdtTrfTxInf.CdtrAgt.FinInstnId.ClrSysMmbId.MmbId : null}</div></FormField>		
			</SpaceBetween></Box>
		</ColumnLayout>
	</Box>
	<Box>
		<Box textAlign="left"><h1>Additional Information</h1></Box>
		<ColumnLayout columns={1}>
				<FormField label="Customer ID" stretch={true}><div className="payment-field">{props.payment ? props.payment.Document.data.Document.CstmrCdtTrfInitn.GrpHdr.MsgId : null}</div></FormField>
				<FormField label="Payment ID" stretch={true}><div className="payment-field">{props.payment ? props.payment.PmtInf.data.PmtInf.PmtInfId : null}</div></FormField>
				<FormField label="End to End ID" stretch={true}><div className="payment-field">{props.payment ? props.payment.CdtTrfTxInf.data.CdtTrfTxInf.PmtId.EndToEndId : null}</div></FormField>
		
		</ColumnLayout>
	</Box>
	</SpaceBetween>
	</>;
	
	var resultBlank = <>
	</>;
		
	//var result = props.payment ? resultPayment : resultBlank;
	return(props.payment ? resultPayment : resultBlank);
}

export function PaymentForm2 (props) {

	const [token,setToken] = React.useState(props.token);
	const [idCustomer, setIDCustomer] = React.useState(generateUUID());
	const [originatorAccount, setOriginatorAccount] = React.useState(null); 
	const [originatorRouting, setOriginatorRouting] = React.useState(null);
	const [date, setDate] = React.useState(format(new Date(), "yyyy-MM-dd"));
	const [amount, setAmount] = React.useState(null);
	const [currency, setCurrency] = React.useState(null);
	const [recipientAccount, setRecipientAccount] = React.useState(null);
	const [recipientRouting, setRecipientRouting] = React.useState(null);
	const [recipientAlias, setRecipientAlias] = React.useState(null);
	const [recipientEmail, setRecipientEmail] = React.useState("");
	const [recipientPhone, setRecipientPhone] = React.useState("");
	const [recipientFirstName, setRecipientFirstName] = React.useState(null);
	const [recipientLastName, setRecipientLastName] = React.useState(null);
	const [recipientAddress, setRecipientAddress] = React.useState(null);
	const [recipientCity, setRecipientCity] = React.useState(null);
	const [recipientState, setRecipientState] = React.useState(null);
	const [recipientPostal, setRecipientPostal] = React.useState(null);

	var aliasOptions = [
		{ label: `${recipientEmail ? recipientEmail : "Email" }`, value: {recipientEmail} },
		{ label: `${recipientPhone ? recipientPhone : "Phone" }`, value: {recipientPhone} },
	];

	function setAccount (account) {
		setOriginatorAccount(account);
		setOriginatorRouting(account.routing);
		setCurrency(account.currency);
	}

	function setAlias (type, value) {
		console.log("---------- setAlias ----------");

		console.log("BEFORE RESET recipientAlias - " + recipientAlias);
		setRecipientAlias(null);
		console.log("AFTER RESET recipientAlias - " + recipientAlias);
		if (type === "email") { console.log("SET EMAIL - " + value); setRecipientEmail(value); console.log("new EMAIL - " + recipientEmail)}
		if (type === "phone") { console.log("SET PHONE - " + value); setRecipientPhone(value); console.log("new PHONE - " + recipientPhone)}
		

		console.log("type - " + type);
		console.log("value - " + value);
		console.log("recipientEmail - " + recipientEmail);
		console.log("recipientPhone - " + recipientPhone);
		
		if (type === "phone" && value !== "" && recipientEmail === "") {
			console.log("EMAIL recipientAlias - " + value);
			setRecipientAlias("phone");
		}
		else if (type === "email" && value !== "" && recipientPhone === "") {
			console.log("EMAIL recipientAlias - " + value);
			setRecipientAlias("email");
		}
		else {
			console.log("ELSE recipientAlias - " + recipientAlias);
		}
		console.log("TARGET recipientAlias - " + recipientAlias);
		
		if (recipientPhone === "" && recipientEmail === "") {
			setRecipientAlias(null);
		}
		console.log("FINAL recipientAlias - " + recipientAlias);
	}
	
	function convertDate (date) {
		var val = date.replace(/\//g, "-");
		return(val);
	}
	
	return(<>
		<SpaceBetween direction="vertical" size="xxl">
		<Box>
		<Header variant="h3" description="">Originator - {token}</Header>
		<Grid gridDefinition={[{ colspan: 6 }, { colspan: 4 }, { colspan: 2 }]}>
			<FormField label="Account" stretch={true}>
		    <Select
		      selectedOption={originatorAccount}
		      onChange={({ detail }) =>
		        setAccount(detail.selectedOption)
		      }
		      options={[
		        { label: "********9012 - Accounts Receivable", value: "123456789012", currency: "USD", routing: "34567890"},
		        { label: "********5342 - Accounts Payable", value: "978675645342", currency: "USD", routing: "34567890" },
		        { label: "********7476 - Cash", value: "847583927476", currency: "USD", routing: "98127634" }
		      ]}
					placeholder="Select an account"
		    />
			</FormField>
			<FormField label="Routing" stretch={true}>
				<Input
					value={originatorRouting}
					onChange={({detail}) => setOriginatorRouting(detail.value)}
					readOnly={true}
				/>
			</FormField>
			<FormField label="Currency" stretch={true}>
				<Input
					value={currency}
					onChange={({detail}) => setCurrency(detail.value)}
					readOnly={true}
				/>
			</FormField>			
		</Grid>
		<Grid gridDefinition={[{ colspan: 3 }, { colspan: 3 }, { colspan: 6 }]}>
			<FormField label="Amount" stretch={true}>
				<Input
					value={amount}
					onChange={({detail}) => setAmount(detail.value)}
					inputMode="decimal"
					type="number"
					placeholder="0.00"
				/>
			</FormField>
			<FormField label="Date" stretch={true}>
				<DateInput
					value={date}
					onChange={({detail}) => setDate(detail.value)}
					placeholder="YYYY/MM/DD"
					expandToViewport={true}
				/>
			</FormField>
			<FormField label="Payment Reference" stretch={true}>
				<Input
					value={idCustomer}
					onChange={({detail}) => setIDCustomer(detail.value)}
				/>
			</FormField>
		</Grid>
		</Box>
		<Box>
		<Header variant="h3" description="">Recipient</Header>
		<Grid gridDefinition={[{ colspan: 5 }, { colspan: 4 }, { colspan: 3 }]}>
			<FormField label="Email" stretch={true}>
				<Input
					value={recipientEmail}
					onChange={({detail}) => { 
						setRecipientEmail(detail.value); 
						if (detail.value == "" && recipientPhone != "") {
							setRecipientAlias("phone");
						}
						else if (detail.value != "" & recipientPhone != "") {
							// do nothing
						}
						else {
							setRecipientAlias((detail.value != "" && recipientPhone == "") ? "email" : null);								
						}
					}}
				/>
			</FormField>
			<FormField label="Account" stretch={true}>
				<Input
					value={recipientAccount}
					onChange={({detail}) => setRecipientAccount(detail.value)}
				/>
			</FormField>
			<FormField label="Routing" stretch={true}>
				<Input
					value={recipientRouting}
					onChange={({detail}) => setRecipientRouting(detail.value)}
				/>
			</FormField>
		</Grid>
		<Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
			<FormField label="First Name" stretch={true}>
				<Input
					value={recipientFirstName}
					onChange={({detail}) => setRecipientFirstName(detail.value)}
				/>
			</FormField>
			<FormField label="Last Name" stretch={true}>
				<Input
					value={recipientLastName}
					onChange={({detail}) => setRecipientLastName(detail.value)}
				/>
			</FormField>
			<FormField label="Phone" stretch={true}>
				<Input
					value={recipientPhone}
					onChange={({detail}) => { 
						setRecipientPhone(detail.value); 						
						if (detail.value == "" && recipientEmail != "") {
							setRecipientAlias("email");
						}
						else if (detail.value != "" & recipientEmail != "") {
							// do nothing
						}
						else {
							setRecipientAlias((detail.value != "" && recipientEmail == "") ? "phone" : null) }}
						}
				></Input>
			</FormField>		
		</Grid>
		<Grid gridDefinition={[{ colspan: 5 }, { colspan: 3 }, { colspan: 2 }, { colspan: 2 }]}>
			<FormField label="Address" stretch={true}>
				<Input
					value={recipientAddress}
					onChange={({detail}) => setRecipientAddress(detail.value)}
				/>
			</FormField>
			<FormField label="City" stretch={true}>
				<Input
					value={recipientCity}
					onChange={({detail}) => setRecipientCity(detail.value)}
				/>
			</FormField>
			<FormField label="State" stretch={true}>
				<Input
					value={recipientState}
					onChange={({detail}) => setRecipientState(detail.value)}
				/>
			</FormField>
			<FormField label="Postal Code" stretch={true}>
				<Input
					value={recipientPostal}
					onChange={({detail}) => setRecipientPostal(detail.value)}
				/>
			</FormField>
		</Grid>
		<Grid gridDefinition={[{ colspan: 12 }]}>
			<FormField label="Payment Alias" stretch={true}>
		   <Tiles
		      onChange={({ detail }) => setRecipientAlias(detail.value)}
					value={recipientAlias}
		      items={[
		        { label: `${recipientEmail != "" ? 'Email: ' + recipientEmail : 'Email'}`, value: "email", disabled: (recipientEmail === "") },
		        { label: `${recipientPhone != "" ? 'Phone: ' + recipientPhone : 'Phone'}`, value: "phone", disabled: (recipientPhone === "") }
		      ]}
		    />
			</FormField>
		</Grid>
		</Box>	
		</SpaceBetween>
	</>);
}

export function PaymentForm ({token, payment}) {

	const session = React.useContext(SessionContext);
  const accounts = [];
  for (let i = 0; i < session.user.customer.accounts.length; i++) {
    let account = session.user.customer.accounts[i];
    //let mask = account.account.replace(/^.{8}/, "********");
    let mask = account.account.replace(/^.{8}/, "\u25CF\u25CF\u25CF\u25CF\u25CF\u25CF\u25CF\u25CF");
    accounts.push({
      label: `${mask} - ${account.name}`,
      value: `${account.account}`,
      currency: `${account.currency}`,
      routing: `${account.routing}`
    });
  }

  payment.originatorID = session.user.customer.id;
  payment.originatorFirstName = session.user.customer.name.first;
  payment.originatorLastName = session.user.customer.name.last;
  payment.originatorAddress = session.user.customer.address.street1;
  payment.originatorCity = session.user.customer.address.city;
  payment.originatorState = session.user.customer.address.state;
  payment.originatorPostal = session.user.customer.address.postal;
  payment.originatorCountry = session.user.customer.address.country;
  
	React.useEffect(() => {
		payment.token = token;
		payment.idCustomer = idCustomer;
		payment.originatorAccount = originatorAccount;
		payment.originatorRouting = originatorRouting;
		payment.date = date;
		payment.amount = amount;
		payment.currency = currency;
		payment.recipientAccount = recipientAccount;
		payment.recipientRouting = recipientRouting;
		payment.recipientAlias = recipientAlias;
		payment.recipientEmail = recipientEmail;
		payment.recipientPhone = recipientPhone;
		payment.recipientFirstName = recipientFirstName;
		payment.recipientLastName = recipientLastName;
		payment.recipientAddress = recipientAddress;
		payment.recipientCity = recipientCity;
		payment.recipientState = recipientState;
		payment.recipientPostal = recipientPostal;
    payment.recipientCountry = recipientCountry;

		payment.idPmtInfId = generateUUID();
		payment.idEndToEndId = generateUUID();
	});

	//const [token,setToken] = React.useState(props.token);
	const [idCustomer, setIDCustomer] = React.useState(generateUUID());
	const [originatorAccount, setOriginatorAccount] = React.useState(null); 
	const [originatorRouting, setOriginatorRouting] = React.useState(null);
	const [date, setDate] = React.useState(format(new Date(), "yyyy-MM-dd"));
	const [amount, setAmount] = React.useState(null);
	const [currency, setCurrency] = React.useState(null);
	const [recipientAccount, setRecipientAccount] = React.useState(null);
	const [recipientRouting, setRecipientRouting] = React.useState(null);
	const [recipientAlias, setRecipientAlias] = React.useState(null);
	const [recipientEmail, setRecipientEmail] = React.useState("");
	const [recipientPhone, setRecipientPhone] = React.useState("");
	const [recipientFirstName, setRecipientFirstName] = React.useState(null);
	const [recipientLastName, setRecipientLastName] = React.useState(null);
	const [recipientAddress, setRecipientAddress] = React.useState(null);
	const [recipientCity, setRecipientCity] = React.useState(null);
	const [recipientState, setRecipientState] = React.useState(null);
	const [recipientPostal, setRecipientPostal] = React.useState(null);
	const [recipientCountry, setRecipientCountry] = React.useState({ label: "US", value: "US" });

	var aliasOptions = [
		{ label: `${recipientEmail ? recipientEmail : "Email" }`, value: {recipientEmail} },
		{ label: `${recipientPhone ? recipientPhone : "Phone" }`, value: {recipientPhone} },
	];

	function setAccount (account) {
		setOriginatorAccount(account);
		setOriginatorRouting(account.routing);
		setCurrency(account.currency);
	}

	function setAlias (type, value) {
		console.log("---------- setAlias ----------");

		console.log("BEFORE RESET recipientAlias - " + recipientAlias);
		setRecipientAlias(null);
		console.log("AFTER RESET recipientAlias - " + recipientAlias);
		if (type === "email") { console.log("SET EMAIL - " + value); setRecipientEmail(value); console.log("new EMAIL - " + recipientEmail)}
		if (type === "phone") { console.log("SET PHONE - " + value); setRecipientPhone(value); console.log("new PHONE - " + recipientPhone)}
		

		console.log("type - " + type);
		console.log("value - " + value);
		console.log("recipientEmail - " + recipientEmail);
		console.log("recipientPhone - " + recipientPhone);
		
		if (type === "phone" && value !== "" && recipientEmail === "") {
			console.log("EMAIL recipientAlias - " + value);
			setRecipientAlias("phone");
		}
		else if (type === "email" && value !== "" && recipientPhone === "") {
			console.log("EMAIL recipientAlias - " + value);
			setRecipientAlias("email");
		}
		else {
			console.log("ELSE recipientAlias - " + recipientAlias);
		}
		console.log("TARGET recipientAlias - " + recipientAlias);
		
		if (recipientPhone === "" && recipientEmail === "") {
			setRecipientAlias(null);
		}
		console.log("FINAL recipientAlias - " + recipientAlias);
	}
	
	function convertDate (date) {
		var val = date.replace(/\//g, "-");
		return(val);
	}
	
	return(<>
		<SpaceBetween direction="vertical" size="xxl">
		<Box>
		<Header variant="h3" description="">Originator - {token}</Header>
		<Grid gridDefinition={[{ colspan: {default: 12, xxs: 6} }, { colspan: {default: 8, xxs: 4} }, { colspan: {default:4, xxs: 2} }]}>
			<FormField label="Account" stretch={true}>
		    <Select
		      selectedOption={originatorAccount}
		      onChange={({ detail }) =>
		        setAccount(detail.selectedOption)
		      }
		      options={accounts}
					placeholder="Select an account"
		    />
			</FormField>
			<FormField label="Routing" stretch={true}>
				<Input
					value={originatorRouting}
					onChange={({detail}) => setOriginatorRouting(detail.value)}
					readOnly={true}
				/>
			</FormField>
			<FormField label="Currency" stretch={true}>
				<Input
					value={currency}
					onChange={({detail}) => setCurrency(detail.value)}
					readOnly={true}
				/>
			</FormField>			
		</Grid>
		<Grid gridDefinition={[{ colspan: {default: 6, xxs: 3} }, { colspan: {default: 6, xxs: 3} }, { colspan: {default: 12, xxs: 6} }]}>
			<FormField label="Amount" stretch={true}>
				<Input
					value={amount}
					onChange={({detail}) => setAmount(detail.value)}
					inputMode="decimal"
					type="number"
					placeholder="0.00"
				/>
			</FormField>
			<FormField label="Date" stretch={true}>
				<DateInput
					value={date}
					onChange={({detail}) => setDate(detail.value)}
					placeholder="YYYY/MM/DD"
					expandToViewport={true}
				/>
			</FormField>
			<FormField label="Payment Reference" stretch={true}>
				<Input
					value={idCustomer}
					onChange={({detail}) => setIDCustomer(detail.value)}
				/>
			</FormField>
		</Grid>
		</Box>
		<Box>
		<Header variant="h3" description="">Recipient</Header>

		<Grid gridDefinition={[{ colspan: {default:12, xxs: 6}}, { colspan: { default: 8, xxs: 4} }, { colspan: { default: 4, xxs: 2} },  
							   { colspan: { default: 6, xxs: 3}}, { colspan: {default:6, xxs:3}}, { colspan: {default: 6, xxs: 3}}, { colspan: {default: 6, xxs:3} },
							   { colspan: {default:12, xxs:4} }, { colspan: {default:6, xxs:3} }, { colspan: {default:2, xxs:2} }, { colspan: {default:4, xxs:3} } ]}>

		{/* Recipient: Row 1 */}
			<FormField label="Account" stretch={true}>
				<Input
					value={recipientAccount}
					onChange={({detail}) => setRecipientAccount(detail.value)}
				/>
			</FormField>
			<FormField label="Routing" stretch={true}>
				<Input
					value={recipientRouting}
					onChange={({detail}) => setRecipientRouting(detail.value)}
				/>
			</FormField>
			<FormField label="Country" stretch={true}>
		    <Select
		      selectedOption={recipientCountry}
		      onChange={({ detail }) =>
		        setRecipientCountry(detail.selectedOption)
		      }
		      options={[
		        { label: "US", value: "US" },
		      ]}
					disabled={false}
		    />
			</FormField>
			<FormField label="First Name" stretch={true}>
				<Input
					value={recipientFirstName}
					onChange={({detail}) => setRecipientFirstName(detail.value)}
				/>
			</FormField>
			<FormField label="Last Name" stretch={true}>
				<Input
					value={recipientLastName}
					onChange={({detail}) => setRecipientLastName(detail.value)}
				/>
			</FormField>

		{/* Recipient: Row 2 */}
			<FormField label="Email" stretch={true}>
				<Input
					value={recipientEmail}
					onChange={({detail}) => { 
						setRecipientEmail(detail.value); 
						if (detail.value == "" && recipientPhone != "") {
							setRecipientAlias("phone");
						}
						else if (detail.value != "" & recipientPhone != "") {
							// do nothing
						}
						else {
							setRecipientAlias((detail.value != "" && recipientPhone == "") ? "email" : null);								
						}
					}}
				/>
			</FormField>

			<FormField label="Phone" stretch={true}>
				<Input
					value={recipientPhone}
					onChange={({detail}) => { 
						setRecipientPhone(detail.value); 						
						if (detail.value == "" && recipientEmail != "") {
							setRecipientAlias("email");
						}
						else if (detail.value != "" & recipientEmail != "") {
							// do nothing
						}
						else {
							setRecipientAlias((detail.value != "" && recipientEmail == "") ? "phone" : null) }}
						}
				></Input>
			</FormField>		

		{/* Recipient: Row 3 */}
			<FormField label="Address" stretch={true}>
				<Input
					value={recipientAddress}
					onChange={({detail}) => setRecipientAddress(detail.value)}
				/>
			</FormField>
			<FormField label="City" stretch={true}>
				<Input
					value={recipientCity}
					onChange={({detail}) => setRecipientCity(detail.value)}
				/>
			</FormField>
			<FormField label="State" stretch={true}>
				<Input
					value={recipientState}
					onChange={({detail}) => setRecipientState(detail.value)}
				/>
			</FormField>
			<FormField label="Postal Code" stretch={false}>
				<Input
					value={recipientPostal}
					onChange={({detail}) => setRecipientPostal(detail.value)}
				/>
			</FormField>
		</Grid>



		<Grid gridDefinition={[{ colspan: 12 }]}>
			<FormField label="Payment Alias" stretch={true}>
		   <Tiles
		      onChange={({ detail }) => setRecipientAlias(detail.value)}
					value={recipientAlias}
		      items={[
		        { label: `${recipientEmail != "" ? 'Email: ' + recipientEmail : 'Email'}`, value: "email", disabled: (recipientEmail === "") },
		        { label: `${recipientPhone != "" ? 'Phone: ' + recipientPhone : 'Phone'}`, value: "phone", disabled: (recipientPhone === "") }
		      ]}
		    />
			</FormField>
		</Grid>
		</Box>	
		</SpaceBetween>
	</>);
}

export function PaymentStatus ({ status, payment }) {

	var ux = null;
	if (status.status == "submitted") {
		ux = <>
			<Box textAlign="left"><h1>Confirmation</h1></Box>		
			<Box><SpaceBetween direction="vertical" size="l">
				<FormField label="Status" stretch={true}><div className="payment-field-submitted">{status.status.toUpperCase()}</div></FormField>
			</SpaceBetween></Box>
		</>				
	} 
	else if (status.status == "accepted") {
		ux = <>
			<Box textAlign="left"><h1>Confirmation</h1></Box>		
			<Box><SpaceBetween direction="vertical" size="l">
				<FormField label="Status" stretch={true}><div className="payment-field-accepted">{status.status.toUpperCase()}</div></FormField>
				<FormField label="User" stretch={true}><div className="payment-field">{payment.token}</div></FormField>
				<FormField label="Reference" stretch={true}><div className="payment-field">{payment.idCustomer}</div></FormField>
			</SpaceBetween></Box>
		</>		
	}
	else if (status.status == "complete") {
		ux = <>
			<Box textAlign="left"><h1>Confirmation</h1></Box>		
			<Box><SpaceBetween direction="vertical" size="l">
				<FormField label="Status" stretch={true}><div className="payment-field-complete">{status.status.toUpperCase()}</div></FormField>
				<FormField label="User" stretch={true}><div className="payment-field">{payment.token}</div></FormField>
				<FormField label="Reference" stretch={true}><div className="payment-field">{payment.idCustomer}</div></FormField>
				<FormField label="Confirmation" stretch={true}><div className="payment-field">{payment.idClearing}</div></FormField>
			</SpaceBetween></Box>
		</>
	}
	else {
		ux = <><h1>Payment initiated successfully...</h1></>
	}

	return(ux);

/*
	return(<>
		<Box textAlign="left"><h1>Payment Confirmation</h1></Box>		
		<Box><SpaceBetween direction="vertical" size="l">
			<FormField label="Status" stretch={true}><div className="payment-field">{status.status}</div></FormField>
			<FormField label="User" stretch={true}><div className="payment-field">{payment.token}</div></FormField>
			<FormField label="Reference" stretch={true}><div className="payment-field">{payment.idCustomer}</div></FormField>
			<FormField label="Confirmation" stretch={true}><div className="payment-field">{payment.idClearing}</div></FormField>
		</SpaceBetween></Box>		
	</>);
*/
}

export function PaymentStatus2 (props) {
	return(<>
		<h1>Payment Submitted Successfully!</h1>
	</>);
}